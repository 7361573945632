import React, { useState, useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import Layout from "../layout";
import SEO from "../components/seo";
import { StoreContext } from "../context";
import {
  darkBlue,
  screen,
  mainWhite,
  Subtitle,
  Button,
  InnerWrapper,
} from "../components/common/variables";
import Loader from "../components/assets/icons/puff.svg";

const Wrapper = styled.div`
  padding-top: 30px;
  padding-bottom: 90px;
  color: ${darkBlue};
  background-color: ${mainWhite};
  @media ${screen.medium} {
    padding-top: 160px;
    padding-bottom: 190px;
  }

  .page-title {
    font-weight: 400;
    line-height: 1.27;

    strong {
      font-weight: 700;
      display: block;
    }
  }

  .description {
    font-size: 1rem;
    max-width: 670px;
    margin: 15px 0 35px 0;
    @media ${screen.medium} {
      margin: 25px 0 50px 0;
    }
    @media ${screen.xLarge} {
      font-size: 1.1rem;
    }
  }

  .product {
    max-width: 670px;
    position: relative;
    margin: 60px 0 0 0;
    @media ${screen.medium} {
      margin: 80px 0 0 0;
    }

    &__row {
      display: flex;
      .col {
        &--1 {
          width: 42%;
          padding: 0 10px 0 0;
          @media ${screen.small} {
            width: 50%;
          }

          .product-name {
            font-size: 0.9rem;
            font-weight: 700;
            @media ${screen.small} {
              font-size: 1.12rem;
            }
            span {
              display: block;
              font-weight: 400;
              margin: 4px 0 0 0;
              @media ${screen.small} {
                margin: 6px 0 0 0;
              }
            }
          }

          .discount-name {
            font-weight: 400;
            font-size: 0.9rem;
            @media ${screen.small} {
              font-size: 1.12rem;
            }
          }

          .total {
            font-size: 0.9rem;
            font-weight: 700;
            @media ${screen.small} {
              font-size: 1.12rem;
            }

            span {
              display: block;
              font-size: 0.88rem;
              font-weight: 400;
            }
          }
        }

        &--2 {
          width: 31%;
          padding: 0 10px 0 0;
          @media ${screen.small} {
            width: 25%;
          }

          .qty-changer {
            border: 1px ${darkBlue} solid;
            height: 38px;
            width: 85px;
            display: flex;
            align-items: center;
            justify-content: center;
            @media ${screen.small} {
              height: 43px;
              width: 108px;
            }

            .minus {
              cursor: pointer;
              font-size: 2.12rem;
              font-weight: 300;
              padding: 0 0 3px 0;
              @media ${screen.small} {
                padding: 0;
              }
            }

            .add {
              cursor: pointer;
              font-size: 1rem;
              @media ${screen.small} {
                font-size: 1.12rem;
              }
            }

            .num {
              font-size: 1.12rem;
              margin: 0 18px;
            }
          }
        }

        &--3 {
          width: 27%;
          text-align: right;
          @media ${screen.small} {
            width: 25%;
            text-align: left;
          }

          .product-price {
            font-size: 1rem;
            @media ${screen.small} {
              font-size: 1.12rem;
            }
          }

          .discount-price {
            font-size: 1rem;
            @media ${screen.small} {
              font-size: 1.12rem;
            }
          }

          .total-price {
            font-size: 1rem;
            font-weight: 700;
            @media ${screen.small} {
              font-size: 1.12rem;
            }
          }
        }
      }

      &--2 {
        margin: 32px 0 0 0;
      }

      &--3 {
        margin: 32px 0 25px 0;
        border-bottom: 1px ${darkBlue} solid;
        border-top: 1px ${darkBlue} solid;
        padding: 20px 0;
      }
    }

    &__checkout {
      display: flex;
      justify-content: flex-end;

      button {
      }
    }

    .loader {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.95);
      z-index: 9;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      svg {
        width: 57px;
        height: 57px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @media ${screen.small} {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
`;

const ClientProgramFacilitatedProductPage = ({ data }) => {
  const { goToCheckout, isLoading, isAustralia } = useContext(StoreContext);

  const [qty, setQty] = useState(1);

  const noGSTFacilitated = data.product.edges.filter((item) => {
    return (
      item.node.variants[0].shopifyId ===
      "gid://shopify/ProductVariant/32109735084083"
    );
  })[0].node;
  const withGSTFacilitated = data.product.edges.filter((item) => {
    return (
      item.node.variants[0].shopifyId ===
      "gid://shopify/ProductVariant/32109355597875"
    );
  })[0].node;

  const facilitatedPrice = Number(withGSTFacilitated.variants[0].price);

  const facilitatedProductVariantId = isAustralia
    ? withGSTFacilitated.variants[0].shopifyId
    : noGSTFacilitated.variants[0].shopifyId;

  const addQty = () => {
    setQty(qty + 1);
  };

  const reduceQty = () => {
    if (qty === 1) return;
    setQty(qty - 1);
  };

  const isEarlybird =
    withGSTFacilitated.tags.some((item) => item === "early bird") ||
    noGSTFacilitated.tags.some((item) => item === "early bird");

  return (
    <Layout>
      <SEO
        title="Client-Centric Advice Program - Facilitated"
        description="Please complete the checkout process and someone from our team will be in touch shortly."
      />
      <Wrapper>
        <InnerWrapper>
          <Subtitle dark={true} className="page-title">
            It’s time to start your journey with the{" "}
            <strong>Client-Centric Advice Program (Enterprise)</strong>
          </Subtitle>
          <p className="description">
            We thank you for your patience and are excited to have you join us!
            Please complete the checkout process and someone from our team will
            be in touch shortly.
          </p>

          <div className="product">
            <div className="product__row">
              <div className="col col--1">
                <h1 className="product-name">
                  Client-Centric Advice Program
                  <span>Enterprise</span>
                </h1>
              </div>
              <div className="col col--2">
                <div className="qty-changer">
                  <span className="minus" onClick={reduceQty}>
                    -
                  </span>
                  <span className="num">{qty}</span>
                  <span className="add" onClick={addQty}>
                    +
                  </span>
                </div>
              </div>
              <div className="col col--3">
                {isEarlybird ? (
                  <span className="product-price">
                    ${(facilitatedPrice + 500) * qty}
                  </span>
                ) : (
                  <span className="product-price">
                    ${facilitatedPrice * qty}
                  </span>
                )}
              </div>
            </div>
            {isEarlybird && (
              <div className="product__row product__row--2">
                <div className="col col--1">
                  <p className="discount-name">Early bird discount applied</p>
                </div>
                <div className="col col--2"></div>
                <div className="col col--3">
                  <span className="discount-price">-${500 * qty}</span>
                </div>
              </div>
            )}

            <div className="product__row product__row--3">
              <div className="col col--1">
                <p className="total">
                  Subtotal
                  {isAustralia && <span>GST not included</span>}
                </p>
              </div>
              <div className="col col--2"></div>
              <div className="col col--3">
                <span className="total-price">${facilitatedPrice * qty}</span>
              </div>
            </div>

            <div className="product__checkout">
              <Button
                dark={true}
                onClick={() => goToCheckout(facilitatedProductVariantId, qty)}
              >
                PROCEED TO PAYMENT
              </Button>
            </div>

            {isLoading && (
              <div className="loader">
                <Loader />
              </div>
            )}
          </div>
        </InnerWrapper>
      </Wrapper>
    </Layout>
  );
};

export default ClientProgramFacilitatedProductPage;

export const dataQuery = graphql`
  {
    product: allShopifyProduct {
      edges {
        node {
          id
          tags
          title
          variants {
            shopifyId
            price
          }
        }
      }
    }
  }
`;
